import {combineReducers} from "redux";
import {smartBetReducer} from "./SmartBet/smartBetReducer";
import {smartBetsReducer} from "./SmartBet/smartBetsReducer";
import {generateSmartBetReducer} from "./SmartBet/generateSmartBetReducer";
import {smartbetTrackingReducer} from "./SmartBet/smartbetTrackingReducer";
import {quickbetReducer} from "./Quickbet/quickbetReducer";
import {quickbetsReducer} from "./Quickbet/quickbetsReducer";
import {quickbetReserveReducer} from "./Quickbet/quickbetReserveReducer";
import {quickbetCreateReducer} from "./Quickbet/quickbetCreateReducer";
import {quicktipReducer} from "./Quicktip/quicktipReducer";
import {quicktipsReducer} from "./Quicktip/quicktipsReducer";
import {quicktipCreateReducer} from "./Quicktip/quicktipCreateReducer";
import {quicktipReserveReducer} from "./Quicktip/quicktipReserveReducer";
import {quicktipTrackingReducer} from "./Quicktip/quicktipTrackingReducer";
import {confirmOTPReducer} from "./OTP/confirmOTPReducer";
import {operatorReducer} from "./Operator/operatorReducer";
import {superbetsReducer} from "./Superbet/superbetsReducer";
import {superbetReducer} from "./Superbet/superbetReducer";
import {superbetTrackingReducer} from "./Superbet/superbetTrackingReducer";
import {playerReducer} from "./Player/playerReducer"
import {historyReducer} from "./History/historyReducer"
import {calculationReducer} from "./Calculation/calculationReducer";
import {jackpotCreateReducer} from "./Jackpot/jackpotCreateReducer";
import {jackpotReducer} from "./Jackpot/jackpotReducer";
import {jackpotsReducer} from "./Jackpot/jackpotsReducer";
import {jackpotTrackingReducer} from "./Jackpot/jackpotTrackingReducer";
import {jackpotPickBetsReducer} from "./Jackpot/jackpotPickBetsReducer";


export const rootReducer = combineReducers({
    smartBetReducer:smartBetReducer,
    smartBetsReducer:smartBetsReducer,
    generateSmartBetReducer:generateSmartBetReducer,
    smartbetTrackingReducer:smartbetTrackingReducer,
    quickbetReducer:quickbetReducer,
    quickbetReserveReducer:quickbetReserveReducer,
    quickbetCreateReducer:quickbetCreateReducer,
    quickbetsReducer:quickbetsReducer,
    quicktipReducer:quicktipReducer,
    quicktipsReducer:quicktipsReducer,
    quicktipCreateReducer:quicktipCreateReducer,
    quicktipReserveReducer:quicktipReserveReducer,
    quicktipTrackingReducer:quicktipTrackingReducer,
    confirmOTPReducer:confirmOTPReducer,
    operatorReducer:operatorReducer,
    superbetsReducer:superbetsReducer,
    superbetReducer:superbetReducer,
    superbetTrackingReducer:superbetTrackingReducer,
    playerReducer:playerReducer,
    historyReducer:historyReducer,
    calculationReducer:calculationReducer,
    jackpotCreateReducer:jackpotCreateReducer,
    jackpotReducer:jackpotReducer,
    jackpotsReducer:jackpotsReducer,
    jackpotTrackingReducer:jackpotTrackingReducer,
    jackpotPickBetsReducer:jackpotPickBetsReducer
});

export type RootState = ReturnType<typeof rootReducer>