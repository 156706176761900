import {SuperbetTrackingAction, SuperbetTrackingState, SuperbetTrackingActionTypes} from "../../types/Superbet/superbetTrackingTypes";

const initialState: SuperbetTrackingState = {
    superbetTrackingeData: [],
    loading: false,
    superbetTrackingeError: null,
};

export const superbetTrackingReducer = (state = initialState, action: SuperbetTrackingAction): SuperbetTrackingState => {
    switch (action.type) {
        case SuperbetTrackingActionTypes.FETCH_SUPERBET_TRACKING_DATA:
            return { ...state, loading: true, superbetTrackingeError: null, superbetTrackingeData: [] };
        case SuperbetTrackingActionTypes.FETCH_SUPERBET_TRACKING_DATA_SUCCESS:
            return { ...state, loading: false, superbetTrackingeError: null, superbetTrackingeData: action.payload };
        case SuperbetTrackingActionTypes.FETCH_SUPERBET_TRACKING_DATA_ERROR:
            return { ...state, loading: false, superbetTrackingeError: action.payload, superbetTrackingeData: [] };
        default:
            return state;
    }
};
