interface ErrorResponse {
    code: number;
    message: string;
}

export interface PlayerState {
    playerData: any;
    loading: boolean;
    playerErrorDara: ErrorResponse | null;
}


export enum PlayerActionTypes {
    FETCH_PLAYER_DATA = "FETCH_PLAYER_DATA",
    FETCH_PLAYER_DATA_SUCCESS = "FETCH_PLAYER_DATA_SUCCESS",
    FETCH_PLAYER_DATA_ERROR = "FETCH_PLAYER_DATA_ERROR",
}

export type PlayerAction =
    | { type: PlayerActionTypes.FETCH_PLAYER_DATA }
    | { type: PlayerActionTypes.FETCH_PLAYER_DATA_SUCCESS; payload: any }
    | { type: PlayerActionTypes.FETCH_PLAYER_DATA_ERROR; payload: any };