import { CalculationCreateAction, CalculationActionTypes, CalculationState, calcData } from "../../types/Calculation/calculation";

// Визначте початкове значення, яке відповідатиме інтерфейсу calcData
const initialCalcData: calcData = {
    price: 0,
    total_odds: 0,
    stake: 0,
    ext: 0,
    ext_amount: 0,
    ext_type: "",
    wht: 0,
    wht_amount: 0,
    gross_payout: 0,
    net_payout: 0
};

const initialState: CalculationState = {
    calculationData: initialCalcData,
    loading: false,
    confirmOTPError: null,
};

export const calculationReducer = (state = initialState, action: CalculationCreateAction): CalculationState => {
    switch (action.type) {
        case CalculationActionTypes.FETCH_CALCULATION_DATA:
            return { ...state, loading: true, confirmOTPError: null, calculationData: initialCalcData };
        case CalculationActionTypes.FETCH_CALCULATION_DATA_SUCCESS:
            return { ...state, loading: false, confirmOTPError: null, calculationData: action.payload };
        case CalculationActionTypes.FETCH_CALCULATION_DATA_ERROR:
            return { ...state, loading: false, confirmOTPError: action.payload, calculationData: initialCalcData };
        default:
            return state;
    }
};