import { Dispatch } from "redux";
import {QuickbetReserveAction, QuickbetReserveActionTypes} from "../../types/Quickbet/quickbetReserveTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const reserveQuickbet = (data: any): any => {
    return async (dispatch: Dispatch<QuickbetReserveAction>) => {
        try {
            dispatch({ type: QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA });
            const response = await axios({
                method: 'post',
                url: `/api/quickbets/reserve`,
                data: data,
                withCredentials: false
            });
            dispatch({ type: QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA_ERROR, payload: errorPayload });
        }
    }
}
