import {QuickbetReserveAction, QuickbetReserveActionTypes, QuickbetReserveState} from "../../types/Quickbet/quickbetReserveTypes";

const initialState: QuickbetReserveState = {
    quickbetReserveData: [],
    loading: false,
    quickbetReservedError: null,
};

export const quickbetReserveReducer = (state = initialState, action: QuickbetReserveAction): QuickbetReserveState => {
    switch (action.type) {
        case QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA:
            return { ...state, loading: true, quickbetReservedError: null, quickbetReserveData: [] };
        case QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA_SUCCESS:
            return { ...state, loading: false, quickbetReservedError: null, quickbetReserveData: action.payload };
        case QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA_ERROR:
            return { ...state, loading: false, quickbetReservedError: action.payload, quickbetReserveData: [] };
        default:
            return state;
    }
};
