interface ErrorResponse {
    code: number;
    message: string;
}

export interface QuickbetReserveState {
    quickbetReserveData: any;
    loading: boolean;
    quickbetReservedError: ErrorResponse | null;
}


export enum QuickbetReserveActionTypes {
    FETCH_QUICKBET_RESERVE_DATA = "FETCH_QUICKBET_RESERVE_DATA",
    FETCH_QUICKBET_RESERVE_DATA_SUCCESS = "FETCH_QUICKBET_RESERVE_DATA_SUCCESS",
    FETCH_QUICKBET_RESERVE_DATA_ERROR = "FETCH_QUICKBET_RESERVE_DATA_ERROR",
}

export type QuickbetReserveAction =
    | { type: QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA }
    | { type: QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA_SUCCESS; payload: any[] }
    | { type: QuickbetReserveActionTypes.FETCH_QUICKBET_RESERVE_DATA_ERROR; payload: any };