import React, {FC} from 'react';
const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;
const Loader: FC = () => {

    return (
        <div className="App">
            <header className="App-header" style={{
                backgroundColor: '#f7f7f7'
            }}>
                <div className="operator-logo">
                    <div style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '50%',
                        position: 'relative',
                        animation: 'rotate 1s linear infinite'
                    }}>
                        <div style={{
                            position: 'absolute',
                            inset: '0px',
                            borderRadius: '50%',
                            border: '5px solid #FFF',
                            animation: 'prixClipFix 2s linear infinite'
                        }}></div>
                        <div style={{
                            position: 'absolute',
                            inset: '6px',
                            borderRadius: '50%',
                            border: '5px solid #f7f7f7',
                            animation: 'prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse'
                        }}></div>
                    </div>
                </div>
                <p>
                    Loading...
                </p>
            </header>
        </div>
    );

}

export default Loader;
