import {Dispatch} from "redux";
import {GenerateSmartBetAction, GenerateSmartBetActionTypes} from "../../types/SmartBet/generateSmartBetTypes";
import axios from "axios";


interface ErrorResponse {
    code: number;
    message: string;
}

export const generateSmartJackpotData = (data: {risk: string, stake: number, win: number, atag: string, card_code: string}): any => {
    return async (dispatch: Dispatch<GenerateSmartBetAction>) => {
        try {
            dispatch({type: GenerateSmartBetActionTypes.FETCH_GENERATE_SMART_BET_DATA});
            const response = await axios({
                method: 'post',
                url: `/api/smartbets/generate`,
                data: data,
                withCredentials: false
            });
            dispatch({type: GenerateSmartBetActionTypes.FETCH_GENERATE_SMART_BET_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: GenerateSmartBetActionTypes.FETCH_GENERATE_SMART_BET_DATA_ERROR, payload: errorPayload });
        }
    }
}