import React, {FC} from "react";
import QuicktipLobby from "./QuicktipLobby";
import SmartbetLobby from "./SmartbetLobby";
import SuperbetLobby from "./SuperbetLobby";
import QuickbetLobby from "./QuickbetLobby";
import JackpotLobby from "./JackpotLobby";
import {useLocation} from "react-router-dom";
import './Lobby.css'
const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;
const Lobby: FC = () => {
    const location = useLocation();
    let authData: string | null = null;
    let atagVal: string | null = null;

    if (location?.search) {
        authData = location.search.match(/token=([^&]*)/)?.[1] || null;
        atagVal = location.search.match(/atag=([^&]*)/)?.[1] || null;
        if (authData !== null) {
            localStorage.setItem('token', authData);
        } else {
            localStorage.removeItem('token');
        }
    } else {
        localStorage.removeItem('token');
    }
    return (
        <div className="Lobby-container lite">
            <main
                // className='pt-2 bg-dark pb-3 text-bg-dark'
                className='pt-2 pb-3'
                style={{
                    backgroundImage: `url(${toAbsoluteUrl('/assets/img/bg/lobby-bg.webp')})`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh'
                }}
            >
                <div className="container">
                    <div className="row">
                        <QuicktipLobby atagParams={atagVal} />
                        <SmartbetLobby atagParams={atagVal} />
                        <SuperbetLobby atagParams={atagVal} />
                        <QuickbetLobby atagParams={atagVal} />
                        <JackpotLobby atagParams={atagVal} />
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Lobby;
