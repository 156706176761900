interface ErrorResponse {
    code: number;
    message: string;
}

export interface QuickbetCreateState {
    quickbetCreateData: any;
    loading: boolean;
    quickbetCreatError: ErrorResponse | null;
}


export enum QuickbetCreateActionTypes {
    FETCH_QUICKBET_CREATE_DATA = "FETCH_QUICKBET_CREATE_DATA",
    FETCH_QUICKBET_CREATE_DATA_SUCCESS = "FETCH_QUICKBET_CREATE_DATA_SUCCESS",
    FETCH_QUICKBET_CREATE_DATA_ERROR = "FETCH_QUICKBET_CREATE_DATA_ERROR",
}

export type QuickbetCreateAction =
    | { type: QuickbetCreateActionTypes.FETCH_QUICKBET_CREATE_DATA }
    | { type: QuickbetCreateActionTypes.FETCH_QUICKBET_CREATE_DATA_SUCCESS; payload: any[] }
    | { type: QuickbetCreateActionTypes.FETCH_QUICKBET_CREATE_DATA_ERROR; payload: any };