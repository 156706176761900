import React from "react";
import './App.css';
import Lobby from "./modules/Lobby/Containers/Lobby";

function App() {
  return (
      <Lobby />
  );
}

export default App;
