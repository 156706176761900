import React, {FC, useEffect, useState} from 'react';
import {useAction} from "../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import QuickbetLobbyComponent from "../Components/QuickbetLobbyComponent";
import {Alert} from "react-bootstrap";
interface QBLProps {
    atagParams: string | null
}
const QuickbetLobby: FC <QBLProps> = ({atagParams}) => {
    const {getQuickbetsData} = useAction();
    const {quickbetsData} = useTypedSelector((state) => state.quickbetsReducer);
    useEffect(()=>{
        getQuickbetsData();
    }, [])
    return (
        <>
            {
                quickbetsData.length > 0 ?
                    <QuickbetLobbyComponent data={quickbetsData} atagParams={atagParams}  />
                    : null
            }
        </>
    );

}

export default QuickbetLobby;
