import {PlayerActionTypes, PlayerState, PlayerAction} from "../../types/Player/playerTypes";

const initialState: PlayerState = {
    playerData: {},
    loading: false,
    playerErrorDara: null,
};

export const playerReducer = (state = initialState, action: PlayerAction): PlayerState => {
    switch (action.type) {
        case PlayerActionTypes.FETCH_PLAYER_DATA:
            return { ...state, loading: true, playerErrorDara: null, playerData: {} };
        case PlayerActionTypes.FETCH_PLAYER_DATA_SUCCESS:
            return { ...state, loading: false, playerErrorDara: null, playerData: action.payload };
        case PlayerActionTypes.FETCH_PLAYER_DATA_ERROR:
            return { ...state, loading: false, playerErrorDara: action.payload, playerData: {} };
        default:
            return state;
    }
};
