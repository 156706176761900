interface ErrorResponse {
    code: number;
    message: string;
}

export interface JackpotState {
    jackpotData: any;
    loading: boolean;
    error: ErrorResponse | null;
}


export enum JackpotActionTypes {
    FETCH_JACKPOT_DATA = "FETCH_JACKPOT_DATA",
    FETCH_JACKPOT_DATA_SUCCESS = "FETCH_JACKPOT_DATA_SUCCESS",
    FETCH_JACKPOT_DATA_ERROR = "FETCH_JACKPOT_DATA_ERROR",
}

export type JackpotAction =
    | { type: JackpotActionTypes.FETCH_JACKPOT_DATA }
    | { type: JackpotActionTypes.FETCH_JACKPOT_DATA_SUCCESS; payload: any[] }
    | { type: JackpotActionTypes.FETCH_JACKPOT_DATA_ERROR; payload: any };