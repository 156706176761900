import {QuickbetCreateState, QuickbetCreateActionTypes,QuickbetCreateAction} from "../../types/Quickbet/quickbetCreateTypes";

const initialState: QuickbetCreateState = {
    quickbetCreateData: [],
    loading: false,
    quickbetCreatError: null,
};

export const quickbetCreateReducer = (state = initialState, action: QuickbetCreateAction): QuickbetCreateState => {
    switch (action.type) {
        case QuickbetCreateActionTypes.FETCH_QUICKBET_CREATE_DATA:
            return { ...state, loading: true, quickbetCreatError: null, quickbetCreateData: [] };
        case QuickbetCreateActionTypes.FETCH_QUICKBET_CREATE_DATA_SUCCESS:
            return { ...state, loading: false, quickbetCreatError: null, quickbetCreateData: action.payload };
        case QuickbetCreateActionTypes.FETCH_QUICKBET_CREATE_DATA_ERROR:
            return { ...state, loading: false, quickbetCreatError: action.payload, quickbetCreateData: [] };
        default:
            return state;
    }
};
