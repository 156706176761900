import {JackpotPickBetsActionTypes, JackpotPickBetsAction, JackpotPickBetsState} from "../../types/Jackpot/jackpotPickBetsTypes";

const initialState: JackpotPickBetsState = {
    jackpotPickBetsData: [],
    loading: false,
    jackpotPickBetsError: null,
};

export const jackpotPickBetsReducer = (state = initialState, action: JackpotPickBetsAction): JackpotPickBetsState => {
    switch (action.type) {
        case JackpotPickBetsActionTypes.FETCH_JACKPOT_PICK_BETS_DATA:
            return { ...state, loading: true, jackpotPickBetsError: null, jackpotPickBetsData: [] };
        case JackpotPickBetsActionTypes.FETCH_JACKPOT_PICK_BETS_DATA_SUCCESS:
            return { ...state, loading: false, jackpotPickBetsError: null, jackpotPickBetsData: action.payload };
        case JackpotPickBetsActionTypes.FETCH_JACKPOT_PICK_BETS_DATA_ERROR:
            return { ...state, loading: false, jackpotPickBetsError: action.payload, jackpotPickBetsData: [] };
        default:
            return state;
    }
};
