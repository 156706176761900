import { Dispatch } from "redux";
import { CalculationActionTypes, CalculationCreateAction, calcData } from "../../types/Calculation/calculation";
import axios from "axios";

// Початкове значення для calcData
const initialCalcData: calcData = {
    price: 0,
    total_odds: 0,
    stake: 0,
    ext: 0,
    ext_amount: 0,
    ext_type: "",
    wht: 0,
    wht_amount: 0,
    gross_payout: 0,
    net_payout: 0
};

interface ErrorResponse {
    code: number;
    message: string;
}

export const calculationTicketsValue = (price: number, oddsArr: number[]): any => {
    return async (dispatch: Dispatch<CalculationCreateAction>) => {
        try {
            dispatch({ type: CalculationActionTypes.FETCH_CALCULATION_DATA });
            const response = await axios.get('/api/finance-details', {
                params: {
                    price: price,
                    odds: oddsArr
                },
                paramsSerializer: params => {
                    const searchParams = new URLSearchParams();
                    for (const key in params) {
                        if (Array.isArray(params[key])) {
                            params[key].forEach((value: any) => {
                                searchParams.append(key, value);
                            });
                        } else {
                            searchParams.append(key, params[key]);
                        }
                    }
                    return searchParams.toString();
                },
                withCredentials: false
            });
            if (response.data) {
                dispatch({ type: CalculationActionTypes.FETCH_CALCULATION_DATA_SUCCESS, payload: response.data });
            } else {
                dispatch({ type: CalculationActionTypes.FETCH_CALCULATION_DATA_SUCCESS, payload: initialCalcData });
            }
        } catch (error: any) {

            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') {
                    errorPayload = {
                        code: error.response.status,
                        message: error.response.data,
                    }
                } else {
                    const { code, message } = error.response.data;
                    errorPayload = { code, message };
                }
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: CalculationActionTypes.FETCH_CALCULATION_DATA_ERROR, payload: errorPayload });
        }
    }
}
