import { Dispatch } from "redux";
import {OperatorActionTypes, OperatorAction} from "../../types/Operator/operatorTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const initOperator = (): any => {
    return async (dispatch: Dispatch<OperatorAction>) => {
        try {
            dispatch({ type: OperatorActionTypes.FETCH_OPERATOR_DATA });
            const response = await axios({
                method: 'get',
                url: `/api/init`,
                withCredentials: false
            });
            if (response.data) {
                dispatch({ type: OperatorActionTypes.FETCH_OPERATOR_DATA_SUCCESS, payload: response.data });
            } else {
                dispatch({ type: OperatorActionTypes.FETCH_OPERATOR_DATA_ERROR, payload: response.status });
            }
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') {
                    console.log(error.response.data.detail);
                    errorPayload = {
                      code: error.response.status,
                      message: error.response.data,
                  }
                } else {
                    errorPayload = {
                        code: error.response.status,
                        message: `Operator Error: ${error.response.data.detail}`
                    }
                }
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: OperatorActionTypes.FETCH_OPERATOR_DATA_ERROR, payload: errorPayload });
        }
    }
}
