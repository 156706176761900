import {QuicktipCreateAction, QuicktipCreateActionTypes, QuicktipCreateState} from "../../types/Quicktip/quicktipCreateTypes";

const initialState: QuicktipCreateState = {
    quicktipCreateData: [],
    loading: false,
    quicktipCreatError: null,
};

export const quicktipCreateReducer = (state = initialState, action: QuicktipCreateAction): QuicktipCreateState => {
    switch (action.type) {
        case QuicktipCreateActionTypes.FETCH_QUICKTIP_CREATE_DATA:
            return { ...state, loading: true, quicktipCreatError: null, quicktipCreateData: [] };
        case QuicktipCreateActionTypes.FETCH_QUICKTIP_CREATE_DATA_SUCCESS:
            return { ...state, loading: false, quicktipCreatError: null, quicktipCreateData: action.payload };
        case QuicktipCreateActionTypes.FETCH_QUICKTIP_CREATE_DATA_ERROR:
            return { ...state, loading: false, quicktipCreatError: action.payload, quicktipCreateData: [] };
        default:
            return state;
    }
};
