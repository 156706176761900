import { Dispatch } from "redux";
import {JackpotReserveAction, JackpotReserveActionTypes} from "../../types/Jackpot/jackpotReserveTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const reserveJackpot = (data: any): any => {
    return async (dispatch: Dispatch<JackpotReserveAction>) => {
        try {
            dispatch({ type: JackpotReserveActionTypes.FETCH_JACKPOT_RESERVE_DATA });
            const response = await axios({
                method: 'post',
                url: `/api/jackpots/reserve`,
                data: data,
                withCredentials: false
            });
            dispatch({ type: JackpotReserveActionTypes.FETCH_JACKPOT_RESERVE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: JackpotReserveActionTypes.FETCH_JACKPOT_RESERVE_DATA_ERROR, payload: errorPayload });
        }
    }
}
