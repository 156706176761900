import React, {FC, useEffect, useState} from 'react';
import {useAction} from "../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import QuicktipLobbyComponent from "../Components/QuicktipLobbyComponent";
import {Alert} from "react-bootstrap";
interface QTLProps {
    atagParams: string | null
}
const QuicktipLobby: FC <QTLProps> = ({atagParams}) => {
    const {getQuicktipsData} = useAction();
    const {quicktipsData} = useTypedSelector((state) => state.quicktipsReducer);

    useEffect(()=>{
        getQuicktipsData();
    }, [])
    return (
        <>
            {
                quicktipsData.length > 0 ?
                    <QuicktipLobbyComponent data={quicktipsData} atagParams={atagParams}  />
                    : null
            }
        </>
    );

}

export default QuicktipLobby;
