interface ErrorResponse {
    code: number;
    message: string;
}

export interface QuicktipCreateState {
    quicktipCreateData: any;
    loading: boolean;
    quicktipCreatError: ErrorResponse | null;
}


export enum QuicktipCreateActionTypes {
    FETCH_QUICKTIP_CREATE_DATA = "FETCH_QUICKTIP_CREATE_DATA",
    FETCH_QUICKTIP_CREATE_DATA_SUCCESS = "FETCH_QUICKTIP_CREATE_DATA_SUCCESS",
    FETCH_QUICKTIP_CREATE_DATA_ERROR = "FETCH_QUICKTIP_CREATE_DATA_ERROR",
}

export type QuicktipCreateAction =
    | { type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CREATE_DATA }
    | { type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CREATE_DATA_SUCCESS; payload: any[] }
    | { type: QuicktipCreateActionTypes.FETCH_QUICKTIP_CREATE_DATA_ERROR; payload: any };