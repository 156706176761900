import {GenerateSmartBetActionTypes, GenerateSmartBetAction, GenerateSmartBetState} from "../../types/SmartBet/generateSmartBetTypes";

const initialState: GenerateSmartBetState = {
    generatedSmartBetData: {},
    loading: false,
    error: null,
};

export const generateSmartBetReducer = (state = initialState, action: GenerateSmartBetAction): GenerateSmartBetState => {
    switch (action.type) {
        case GenerateSmartBetActionTypes.FETCH_GENERATE_SMART_BET_DATA:
            return { ...state, loading: true, error: null, generatedSmartBetData: {} };
        case GenerateSmartBetActionTypes.FETCH_GENERATE_SMART_BET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, generatedSmartBetData: action.payload };
        case GenerateSmartBetActionTypes.FETCH_GENERATE_SMART_BET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, generatedSmartBetData: {} };
        default:
            return state;
    }
};
