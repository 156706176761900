import {SmartbetTrackingAction, SmartbetTrackingActionTypes, SmartbetTrackingState} from "../../types/SmartBet/smartbetTrackingTypes";

const initialState: SmartbetTrackingState = {
    smartbetTrackingeData: [],
    loading: false,
    smartbetTrackingeError: null,
};

export const smartbetTrackingReducer = (state = initialState, action: SmartbetTrackingAction): SmartbetTrackingState => {
    switch (action.type) {
        case SmartbetTrackingActionTypes.FETCH_SMARTBET_TRACKING_DATA:
            return { ...state, loading: true, smartbetTrackingeError: null, smartbetTrackingeData: [] };
        case SmartbetTrackingActionTypes.FETCH_SMARTBET_TRACKING_DATA_SUCCESS:
            return { ...state, loading: false, smartbetTrackingeError: null, smartbetTrackingeData: action.payload };
        case SmartbetTrackingActionTypes.FETCH_SMARTBET_TRACKING_DATA_ERROR:
            return { ...state, loading: false, smartbetTrackingeError: action.payload, smartbetTrackingeData: [] };
        default:
            return state;
    }
};
