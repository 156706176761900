interface ErrorResponse {
    code: number;
    message: string;
}

export interface ConfirmOTPState {
    confirmOTPData: any|number;
    loading: boolean;
    confirmOTPError: ErrorResponse | null;
}


export enum ConfirmOTPActionTypes {
    FETCH_CONFIRM_OTP_DATA = "FETCH_CONFIRM_OTP_DATA",
    FETCH_CONFIRM_OTP_DATA_SUCCESS = "FETCH_CONFIRM_OTP_DATA_SUCCESS",
    FETCH_CONFIRM_OTP_DATA_ERROR = "FETCH_CONFIRM_OTP_DATA_ERROR",
}

export type ConfirmOTPCreateAction =
    | { type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA }
    | { type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_SUCCESS; payload: any[]|number }
    | { type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_ERROR; payload: any };