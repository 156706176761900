interface ErrorResponse {
    code: number;
    message: string;
}

export interface HistoryState {
    historyData: any;
    loading: boolean;
    operatorErrorDara: ErrorResponse | null;
}


export enum HistoryActionTypes {
    FETCH_HISTORY_DATA = "FETCH_HISTORY_DATA",
    FETCH_HISTORY_DATA_SUCCESS = "FETCH_HISTORY_DATA_SUCCESS",
    FETCH_HISTORY_DATA_ERROR = "FETCH_HISTORY_DATA_ERROR",
}

export type HistoryAction =
    | { type: HistoryActionTypes.FETCH_HISTORY_DATA }
    | { type: HistoryActionTypes.FETCH_HISTORY_DATA_SUCCESS; payload: any }
    | { type: HistoryActionTypes.FETCH_HISTORY_DATA_ERROR; payload: any };