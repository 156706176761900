import {OperatorActionTypes, OperatorState, OperatorAction} from "../../types/Operator/operatorTypes";

const initialState: OperatorState = {
    operatorData: {},
    loading: false,
    operatorErrorDara: null,
};

export const operatorReducer = (state = initialState, action: OperatorAction): OperatorState => {
    switch (action.type) {
        case OperatorActionTypes.FETCH_OPERATOR_DATA:
            return { ...state, loading: true, operatorErrorDara: null, operatorData: {} };
        case OperatorActionTypes.FETCH_OPERATOR_DATA_SUCCESS:
            return { ...state, loading: false, operatorErrorDara: null, operatorData: action.payload };
        case OperatorActionTypes.FETCH_OPERATOR_DATA_ERROR:
            return { ...state, loading: false, operatorErrorDara: action.payload, operatorData: {} };
        default:
            return state;
    }
};
