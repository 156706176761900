import React, {FC, useEffect, useState} from 'react';
import {useAction} from "../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import JackpotLobbyComponent from "../Components/JackpotLobbyComponent";
import {Alert} from "react-bootstrap";
import Jackpot from "../../Jackpot/Container/Jackpot";
interface QBLProps {
    atagParams: string | null
}
const QuickbetLobby: FC <QBLProps> = ({atagParams}) => {
    const {getJackpotsData} = useAction();
    const {jackpotsData} = useTypedSelector((state) => state.jackpotsReducer);
    useEffect(()=>{
        getJackpotsData();
    }, [])
    return (
        <>
            {
                jackpotsData.length > 0 ?
                    <JackpotLobbyComponent data={jackpotsData} atagParams={atagParams}  />
                    : null
            }
        </>
    );

}

export default QuickbetLobby;
