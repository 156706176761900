import React from 'react';
import {createRoot} from 'react-dom/client';
import {AppRoutes} from "./app/routing/AppRoutes";
import i18n from './app/i18n/i18n';
import { I18nextProvider } from 'react-i18next';
import {Provider} from "react-redux";
import {store} from "./store";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const container = document.getElementById('root')

if (container) {
    createRoot(container).render(
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <AppRoutes />
            </I18nextProvider>
        </Provider>
    )
}
