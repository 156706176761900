import { Dispatch } from "redux";
import {SuperbetActionTypes, SuperbetAction} from "../../types/Superbet/superbetTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const getSuperbetData = (id: string): any => {
    return async (dispatch: Dispatch<SuperbetAction>) => {
        try {
            dispatch({ type: SuperbetActionTypes.FETCH_SUPERBET_DATA });
            const response = await axios({
                method: 'get',
                url: `/api/superbet_cards/${id}`,
                withCredentials: false
            });
            dispatch({ type: SuperbetActionTypes.FETCH_SUPERBET_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: SuperbetActionTypes.FETCH_SUPERBET_DATA_ERROR, payload: errorPayload });
        }
    }
}
