import {JackpotsState, JackpotsActionTypes, JackpotsAction} from "../../types/Jackpot/jackpotsTypes";

const initialState: JackpotsState = {
    jackpotsData: [],
    loading: false,
    error: null,
};

export const jackpotsReducer = (state = initialState, action: JackpotsAction): JackpotsState => {
    switch (action.type) {
        case JackpotsActionTypes.FETCH_JACKPOTS_DATA:
            return { ...state, loading: true, error: null, jackpotsData: [] };
        case JackpotsActionTypes.FETCH_JACKPOTS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, jackpotsData: action.payload };
        case JackpotsActionTypes.FETCH_JACKPOTS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, jackpotsData: [] };
        default:
            return state;
    }
};
