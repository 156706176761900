import { Dispatch } from "redux";
import {PlayerAction, PlayerActionTypes} from "../../types/Player/playerTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const initPlayer = (token: string): any => {
    return async (dispatch: Dispatch<PlayerAction>) => {
        try {
            dispatch({ type: PlayerActionTypes.FETCH_PLAYER_DATA });
            const response = await axios({
                method: 'get',
                url: `/api/identify?token=${token}`,
                withCredentials: false
            });
            if (response.data) {
                dispatch({ type: PlayerActionTypes.FETCH_PLAYER_DATA_SUCCESS, payload: response.data });
            } else {
                dispatch({ type: PlayerActionTypes.FETCH_PLAYER_DATA_ERROR, payload: response.status });
            }
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') {
                    console.log(error.response.data.detail);
                    errorPayload = {
                      code: error.response.status,
                      message: error.response.data,
                  }
                } else {
                    errorPayload = {
                        code: error.response.status,
                        message: `Operator Error: ${error.response.data.detail}`
                    }
                }
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: PlayerActionTypes.FETCH_PLAYER_DATA_ERROR, payload: errorPayload });
        }
    }
}
