import {JackpotTrackingAction, JackpotTrackingActionTypes, JackpotTrackingState} from "../../types/Jackpot/jackpotTrackingTypes";

const initialState: JackpotTrackingState = {
    jackpotTrackingeData: [],
    loading: false,
    jackpotTrackingeError: null,
};

export const jackpotTrackingReducer = (state = initialState, action: JackpotTrackingAction): JackpotTrackingState => {
    switch (action.type) {
        case JackpotTrackingActionTypes.FETCH_JACKPOT_TRACKING_DATA:
            return { ...state, loading: true, jackpotTrackingeError: null, jackpotTrackingeData: [] };
        case JackpotTrackingActionTypes.FETCH_JACKPOT_TRACKING_DATA_SUCCESS:
            return { ...state, loading: false, jackpotTrackingeError: null, jackpotTrackingeData: action.payload };
        case JackpotTrackingActionTypes.FETCH_JACKPOT_TRACKING_DATA_ERROR:
            return { ...state, loading: false, jackpotTrackingeError: action.payload, jackpotTrackingeData: [] };
        default:
            return state;
    }
};
