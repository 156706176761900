
interface ErrorResponse {
    code: number;
    message: string;
}

export interface calcData {
    price: number;
    total_odds: number;
    stake: number;
    ext: number;
    ext_amount: number;
    ext_type: string;
    wht: number;
    wht_amount: number;
    gross_payout: number;
    net_payout: number;
}

export interface CalculationState {
    calculationData: calcData;
    loading: boolean;
    confirmOTPError: ErrorResponse | null;
}

export enum CalculationActionTypes {
    FETCH_CALCULATION_DATA = "FETCH_CONFIRM_OTP_DATA",
    FETCH_CALCULATION_DATA_SUCCESS = "FETCH_CONFIRM_OTP_DATA_SUCCESS",
    FETCH_CALCULATION_DATA_ERROR = "FETCH_CONFIRM_OTP_DATA_ERROR",
}

export type CalculationCreateAction =
    | { type: CalculationActionTypes.FETCH_CALCULATION_DATA }
    | { type: CalculationActionTypes.FETCH_CALCULATION_DATA_SUCCESS; payload: calcData }
    | { type: CalculationActionTypes.FETCH_CALCULATION_DATA_ERROR; payload: ErrorResponse };