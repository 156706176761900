interface ErrorResponse {
    code: number;
    message: string;
}

export interface SuperbetTrackingState {
    superbetTrackingeData: any;
    loading: boolean;
    superbetTrackingeError: ErrorResponse | null;
}


export enum SuperbetTrackingActionTypes {
    FETCH_SUPERBET_TRACKING_DATA = "FETCH_SUPERBET_TRACKING_DATA",
    FETCH_SUPERBET_TRACKING_DATA_SUCCESS = "FETCH_SUPERBET_TRACKING_DATA_SUCCESS",
    FETCH_SUPERBET_TRACKING_DATA_ERROR = "FETCH_SUPERBET_TRACKING_DATA_ERROR",
}

export type SuperbetTrackingAction =
    | { type: SuperbetTrackingActionTypes.FETCH_SUPERBET_TRACKING_DATA }
    | { type: SuperbetTrackingActionTypes.FETCH_SUPERBET_TRACKING_DATA_SUCCESS; payload: any[] }
    | { type: SuperbetTrackingActionTypes.FETCH_SUPERBET_TRACKING_DATA_ERROR; payload: any };