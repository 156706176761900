import { Dispatch } from "redux";
import {SuperbetsAction, SuperbetsActionTypes} from "../../types/Superbet/superbetsTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const getSuperbetsData = (): any => {
    return async (dispatch: Dispatch<SuperbetsAction>) => {
        try {
            dispatch({ type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA });
            const response = await axios({
                method: 'get',
                url: `/api/superbet_cards`,
                withCredentials: false
            });
            dispatch({ type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA_ERROR, payload: errorPayload });
        }
    }
}
