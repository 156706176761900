import React, {FC, useEffect, useState} from 'react';
import {useAction} from "../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import {Link} from "react-router-dom";
import SuperbetLobbyConponent from "../Components/SuperbetLobbyComponent";
import {Alert} from "react-bootstrap";

interface SIL {
    atagParams: string | null
}
const SuperbetLobby: FC <SIL> = ({atagParams}) => {
    const {getSuperbetsData} = useAction();
    const {superbetsData} = useTypedSelector((state) => state.superbetsReducer);

    useEffect(()=>{
        getSuperbetsData();
    }, [])
    return (
        <>
            {
                superbetsData.length > 0 ?
                    <SuperbetLobbyConponent data={superbetsData} atagParams={atagParams} />
                    : null
            }
        </>
    );

}

export default SuperbetLobby;
