interface ErrorResponse {
    code: number;
    message: string;
}

export interface JackpotsState {
    jackpotsData: any;
    loading: boolean;
    error: ErrorResponse | null;
}


export enum JackpotsActionTypes {
    FETCH_JACKPOTS_DATA = "FETCH_JACKPOTS_DATA",
    FETCH_JACKPOTS_DATA_SUCCESS = "FETCH_JACKPOTS_DATA_SUCCESS",
    FETCH_JACKPOTS_DATA_ERROR = "FETCH_JACKPOTS_DATA_ERROR",
}

export type JackpotsAction =
    | { type: JackpotsActionTypes.FETCH_JACKPOTS_DATA }
    | { type: JackpotsActionTypes.FETCH_JACKPOTS_DATA_SUCCESS; payload: any[] }
    | { type: JackpotsActionTypes.FETCH_JACKPOTS_DATA_ERROR; payload: any };