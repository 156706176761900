import {HistoryAction, HistoryActionTypes, HistoryState} from "../../types/History/historyTypes";

const initialState: HistoryState = {
    historyData: {},
    loading: false,
    operatorErrorDara: null,
};

export const historyReducer = (state = initialState, action: HistoryAction): HistoryState => {
    switch (action.type) {
        case HistoryActionTypes.FETCH_HISTORY_DATA:
            return { ...state, loading: true, operatorErrorDara: null, historyData: {} };
        case HistoryActionTypes.FETCH_HISTORY_DATA_SUCCESS:
            return { ...state, loading: false, operatorErrorDara: null, historyData: action.payload };
        case HistoryActionTypes.FETCH_HISTORY_DATA_ERROR:
            return { ...state, loading: false, operatorErrorDara: action.payload, historyData: {} };
        default:
            return state;
    }
};
