import {QuickbetAction, QuickbetState, QuickbetActionTypes} from "../../types/Quickbet/quickbetTypes";

const initialState: QuickbetState = {
    quickbetData: [],
    loading: false,
    error: null,
};

export const quickbetReducer = (state = initialState, action: QuickbetAction): QuickbetState => {
    switch (action.type) {
        case QuickbetActionTypes.FETCH_QUICKBET_DATA:
            return { ...state, loading: true, error: null, quickbetData: [] };
        case QuickbetActionTypes.FETCH_QUICKBET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, quickbetData: action.payload };
        case QuickbetActionTypes.FETCH_QUICKBET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, quickbetData: [] };
        default:
            return state;
    }
};
