import {QuicktipsActionTypes, QuicktipsAction, QuicktipsState} from "../../types/Quicktip/quicktipsTypes";

const initialState: QuicktipsState = {
    quicktipsData: [],
    loading: false,
    error: null,
};

export const quicktipsReducer = (state = initialState, action: QuicktipsAction): QuicktipsState => {
    switch (action.type) {
        case QuicktipsActionTypes.FETCH_QUICKTIPS_DATA:
            return { ...state, loading: true, error: null, quicktipsData: [] };
        case QuicktipsActionTypes.FETCH_QUICKTIPS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, quicktipsData: action.payload };
        case QuicktipsActionTypes.FETCH_QUICKTIPS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, quicktipsData: [] };
        default:
            return state;
    }
};
