interface ErrorResponse {
    code: number;
    message: string;
}

export interface SuperbetState {
    superbetsData: any[];
    loading: boolean;
    error: ErrorResponse | null;
}


export enum SuperbetsActionTypes {
    FETCH_SUPERBETS_DATA = "FETCH_SUPERBETS_DATA",
    FETCH_SUPERBETS_DATA_SUCCESS = "FETCH_SUPERBETS_DATA_SUCCESS",
    FETCH_SUPERBETS_DATA_ERROR = "FETCH_SUPERBETS_DATA_ERROR",
}

export type SuperbetsAction =
    | { type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA }
    | { type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA_SUCCESS; payload: any[] }
    | { type: SuperbetsActionTypes.FETCH_SUPERBETS_DATA_ERROR; payload: any };