import {ConfirmOTPActionTypes, ConfirmOTPCreateAction, ConfirmOTPState} from "../../types/OTP/confirmOTP";

const initialState: ConfirmOTPState = {
    confirmOTPData: {},
    loading: false,
    confirmOTPError: null,
};

export const confirmOTPReducer = (state = initialState, action: ConfirmOTPCreateAction): ConfirmOTPState => {
    switch (action.type) {
        case ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA:
            return { ...state, loading: true, confirmOTPError: null, confirmOTPData: [] };
        case ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_SUCCESS:
            return { ...state, loading: false, confirmOTPError: null, confirmOTPData: action.payload };
        case ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_ERROR:
            return { ...state, loading: false, confirmOTPError: action.payload, confirmOTPData: [] };
        default:
            return state;
    }
};
