import {Dispatch} from "redux";
import {SmartBetAction, SmartBetActionTypes} from "../../types/SmartBet/smartBetTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const getSmartJackpotData = (id:string): any => {
    return async (dispatch: Dispatch<SmartBetAction>) => {
        try {
            dispatch({type: SmartBetActionTypes.FETCH_SMART_BET_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/smartbet_cards/${id}`,
                withCredentials: false
            });
            dispatch({type: SmartBetActionTypes.FETCH_SMART_BET_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: SmartBetActionTypes.FETCH_SMART_BET_DATA_ERROR, payload: errorPayload });
        }
    }
}