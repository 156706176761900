import {JackpotCreateActionTypes, JackpotCreateAction, JackpotCreateState} from "../../types/Jackpot/jackpotCreateTypes";

const initialState: JackpotCreateState = {
    jackpotCreateData: [],
    loading: false,
    jackpotCreatError: null,
};

export const jackpotCreateReducer = (state = initialState, action: JackpotCreateAction): JackpotCreateState => {
    switch (action.type) {
        case JackpotCreateActionTypes.FETCH_JACKPOT_CREATE_DATA:
            return { ...state, loading: true, jackpotCreatError: null, jackpotCreateData: [] };
        case JackpotCreateActionTypes.FETCH_JACKPOT_CREATE_DATA_SUCCESS:
            return { ...state, loading: false, jackpotCreatError: null, jackpotCreateData: action.payload };
        case JackpotCreateActionTypes.FETCH_JACKPOT_CREATE_DATA_ERROR:
            return { ...state, loading: false, jackpotCreatError: action.payload, jackpotCreateData: [] };
        default:
            return state;
    }
};
