import React, {FC} from 'react';
import {Smartbet} from "./helper";
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";
interface Props {
    data: Smartbet[];
    atagParams: string | null
}
const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;
const SmartbetLobbyComponent: FC<Props> = ({data,atagParams}) => {

    return (
        <div className="col-12">
            {
                data.length > 0 ?
                    <div className="card smartbet-lobby-card mt-3">
                        <div className="card-header" style={{color: '#005A70', fontWeight: '700'}}>
                            Smartbet
                        </div>
                        <ul className='mt-3'>
                            {
                                data.length > 0 ?
                                    data.map((item: any, index: number) => {
                                        return (
                                            <li key={`${index}smartBetsData`}>
                                                <Link to={atagParams ? `smartbet/${item.code}?atag=${atagParams}` : `smartbet/${item.code}`}
                                                      className='smartbet-card-link text-decoration-none'>
                                                    <img
                                                        src={`${item.preview_img}`}
                                                        onError={(e) => { e.currentTarget.src = toAbsoluteUrl('/assets/img/events/league/F2964C.png'); }}
                                                        width='100' height='100' alt="sb-logo"/>
                                                    {/*<span className='text-center text-white'>{item.title.length > 0 ? item.title : null}</span>*/}
                                                    <span
                                                        className='text-center text-green'>{item.title.length > 0 ? item.title : null}</span>
                                                </Link>
                                            </li>
                                        )
                                    }) : null
                            }
                        </ul>
                    </div>
                    : <Alert>Loading...</Alert>
            }
        </div>
    );

}

export default SmartbetLobbyComponent;
