import {QuicktipReserveAction, QuicktipReserveState, QuicktipReserveActionTypes} from "../../types/Quicktip/quicktipReserveTypes";

const initialState: QuicktipReserveState = {
    quicktipReserveData: [],
    loading: false,
    quicktipReservedError: null,
};

export const quicktipReserveReducer = (state = initialState, action: QuicktipReserveAction): QuicktipReserveState => {
    switch (action.type) {
        case QuicktipReserveActionTypes.FETCH_QUICKTIP_RESERVE_DATA:
            return { ...state, loading: true, quicktipReservedError: null, quicktipReserveData: [] };
        case QuicktipReserveActionTypes.FETCH_QUICKTIP_RESERVE_DATA_SUCCESS:
            return { ...state, loading: false, quicktipReservedError: null, quicktipReserveData: action.payload };
        case QuicktipReserveActionTypes.FETCH_QUICKTIP_RESERVE_DATA_ERROR:
            return { ...state, loading: false, quicktipReservedError: action.payload, quicktipReserveData: [] };
        default:
            return state;
    }
};
