import React, {FC, useEffect, useState} from 'react';
import {useAction} from "../../../../store/hooks/useAction";
import {useTypedSelector} from "../../../../store/hooks/useTypedSelector";
import SmartbetLobbyComponent from "../Components/SmartbetLobbyComponent";
import {Link} from "react-router-dom";
import {Alert} from "react-bootstrap";
interface STLProps {
    atagParams: string | null
}
const SmartbetLobby: FC <STLProps> = ({atagParams}) => {
    const {getSmartBetsData} = useAction();
    const {smartBetsData} = useTypedSelector((state) => state.smartBetsReducer);

    useEffect(()=>{
        getSmartBetsData();
    }, [])
    return(
        <>
            {smartBetsData.length > 0 ?
                <SmartbetLobbyComponent data={smartBetsData} atagParams={atagParams} />
                : null
            }
        </>
    )

}

export default SmartbetLobby;
