interface ErrorResponse {
    code: number;
    message: string;
}

export interface SuperbetState {
    superbetData: any;
    loading: boolean;
    error: ErrorResponse | null;
}


export enum SuperbetActionTypes {
    FETCH_SUPERBET_DATA = "FETCH_SUPERBET_DATA",
    FETCH_SUPERBET_DATA_SUCCESS = "FETCH_SUPERBET_DATA_SUCCESS",
    FETCH_SUPERBET_DATA_ERROR = "FETCH_SUPERBET_DATA_ERROR",
}

export type SuperbetAction =
    | { type: SuperbetActionTypes.FETCH_SUPERBET_DATA }
    | { type: SuperbetActionTypes.FETCH_SUPERBET_DATA_SUCCESS; payload: any[] }
    | { type: SuperbetActionTypes.FETCH_SUPERBET_DATA_ERROR; payload: any };