import {Dispatch} from "redux";
import {SmartBetsAction, SmartBetsActionTypes} from "../../types/SmartBet/smartBetsTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const getSmartBetsData = (): any => {
    return async (dispatch: Dispatch<SmartBetsAction>) => {
        try {
            dispatch({type: SmartBetsActionTypes.FETCH_SMART_BETS_DATA});
            const response = await axios({
                method: 'get',
                url: `/api/smartbet_cards`,
                withCredentials: false
            });
            dispatch({type: SmartBetsActionTypes.FETCH_SMART_BETS_DATA_SUCCESS, payload: response.data})
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                const { code, message } = error.response.data;
                errorPayload = { code, message };
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: SmartBetsActionTypes.FETCH_SMART_BETS_DATA_ERROR, payload: errorPayload });
        }
    }
}