import {QuicktipTrackingActionTypes, QuicktipTrackingAction, QuicktipTrackingState} from "../../types/Quicktip/quicktipTrackingTypes";

const initialState: QuicktipTrackingState = {
    quicktipTrackingeData: [],
    loading: false,
    quicktipTrackingeError: null,
};

export const quicktipTrackingReducer = (state = initialState, action: QuicktipTrackingAction): QuicktipTrackingState => {
    switch (action.type) {
        case QuicktipTrackingActionTypes.FETCH_QUICKTIP_TRACKING_DATA:
            return { ...state, loading: true, quicktipTrackingeError: null, quicktipTrackingeData: [] };
        case QuicktipTrackingActionTypes.FETCH_QUICKTIP_TRACKING_DATA_SUCCESS:
            return { ...state, loading: false, quicktipTrackingeError: null, quicktipTrackingeData: action.payload };
        case QuicktipTrackingActionTypes.FETCH_QUICKTIP_TRACKING_DATA_ERROR:
            return { ...state, loading: false, quicktipTrackingeError: action.payload, quicktipTrackingeData: [] };
        default:
            return state;
    }
};
