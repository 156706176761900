import { Dispatch } from "redux";
import {HistoryActionTypes, HistoryAction} from "../../types/History/historyTypes";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const getHistoryData = (code: string): any => {
    return async (dispatch: Dispatch<HistoryAction>) => {
        try {
            dispatch({ type: HistoryActionTypes.FETCH_HISTORY_DATA });
            const response = await axios({
                method: 'get',
                url: `/api/tickets/${code}`,
                withCredentials: false
            });
            if (response.data) {
                dispatch({ type: HistoryActionTypes.FETCH_HISTORY_DATA_SUCCESS, payload: response.data });
            } else {
                dispatch({ type: HistoryActionTypes.FETCH_HISTORY_DATA_ERROR, payload: response.status });
            }
        } catch (error: any) {
            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') {
                    console.log(error.response.data.detail);
                    errorPayload = {
                      code: error.response.status,
                      message: error.response.data,
                  }
                } else {
                    errorPayload = {
                        code: error.response.status,
                        message: `Operator Error: ${error.response.data.detail}`
                    }
                }
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: HistoryActionTypes.FETCH_HISTORY_DATA, payload: errorPayload });
        }
    }
}
