import {SuperbetsAction, SuperbetsActionTypes, SuperbetState} from "../../types/Superbet/superbetsTypes";

const initialState: SuperbetState = {
    superbetsData: [],
    loading: false,
    error: null,
};

export const superbetsReducer = (state = initialState, action: SuperbetsAction): SuperbetState => {
    switch (action.type) {
        case SuperbetsActionTypes.FETCH_SUPERBETS_DATA:
            return { ...state, loading: true, error: null, superbetsData: [] };
        case SuperbetsActionTypes.FETCH_SUPERBETS_DATA_SUCCESS:
            return { ...state, loading: false, error: null, superbetsData: action.payload };
        case SuperbetsActionTypes.FETCH_SUPERBETS_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, superbetsData: [] };
        default:
            return state;
    }
};
