import { Dispatch } from "redux";
import {ConfirmOTPActionTypes, ConfirmOTPCreateAction} from "../../types/OTP/confirmOTP";
import axios from "axios";

interface ErrorResponse {
    code: number;
    message: string;
}

export const confirmOTP = (data: any): any => {
    return async (dispatch: Dispatch<ConfirmOTPCreateAction>) => {
        try {
            dispatch({ type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA });
            const response = await axios({
                method: 'post',
                url: `/api/otp/confirm`,
                data: data,
                withCredentials: false
            });
            if (response.data) {
                dispatch({ type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_SUCCESS, payload: response.data });
            } else {
                dispatch({ type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_SUCCESS, payload: response.status });
            }
        } catch (error: any) {

            let errorPayload: ErrorResponse;
            if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') {
                  errorPayload = {
                      code: error.response.status,
                      message: error.response.data,
                  }
                } else {
                    const { code, message } = error.response.data;
                    errorPayload = { code, message };
                }
            } else {
                errorPayload = { code: -1, message: 'Unknown error occurred' };
            }
            dispatch({ type: ConfirmOTPActionTypes.FETCH_CONFIRM_OTP_DATA_ERROR, payload: errorPayload });
        }
    }
}
