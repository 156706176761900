interface ErrorResponse {
    code: number;
    message: string;
}

export interface QuicktipReserveState {
    quicktipReserveData: any;
    loading: boolean;
    quicktipReservedError: ErrorResponse | null;
}


export enum QuicktipReserveActionTypes {
    FETCH_QUICKTIP_RESERVE_DATA = "FETCH_QUICKTIP_RESERVE_DATA",
    FETCH_QUICKTIP_RESERVE_DATA_SUCCESS = "FETCH_QUICKTIP_RESERVE_DATA_SUCCESS",
    FETCH_QUICKTIP_RESERVE_DATA_ERROR = "FETCH_QUICKTIP_RESERVE_DATA_ERROR",
}

export type QuicktipReserveAction =
    | { type: QuicktipReserveActionTypes.FETCH_QUICKTIP_RESERVE_DATA }
    | { type: QuicktipReserveActionTypes.FETCH_QUICKTIP_RESERVE_DATA_SUCCESS; payload: any[] }
    | { type: QuicktipReserveActionTypes.FETCH_QUICKTIP_RESERVE_DATA_ERROR; payload: any };