import {SuperbetActionTypes, SuperbetState, SuperbetAction} from "../../types/Superbet/superbetTypes";

const initialState: SuperbetState = {
    superbetData: [],
    loading: false,
    error: null,
};

export const superbetReducer = (state = initialState, action: SuperbetAction): SuperbetState => {
    switch (action.type) {
        case SuperbetActionTypes.FETCH_SUPERBET_DATA:
            return { ...state, loading: true, error: null, superbetData: [] };
        case SuperbetActionTypes.FETCH_SUPERBET_DATA_SUCCESS:
            return { ...state, loading: false, error: null, superbetData: action.payload };
        case SuperbetActionTypes.FETCH_SUPERBET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload, superbetData: [] };
        default:
            return state;
    }
};
